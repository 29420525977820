<template lang="">
  <div>
    <b-card-code title="Update Image">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- cover -->
            <b-col md="12">
              <div align="center" class="text-center">
                <h4>Cover</h4>
                <b-media class="mb-1 mr-auto">
                  <b-avatar class="" ref="previewEl" :src="img" size="180px" />
                </b-media>
                <div class="mb-4 ">
                  <b-button variant="primary" @click="$refs.refInputEl.click()">
                    <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @change="uploadImage"
                    />
                    <feather-icon icon="EditIcon" class=" " />
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group label="Description" label-for="description">
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-input
                    id="description"
                    v-model="data.description"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
      <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{value}}</li>
                </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import { required } from '@validations'
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BCardCode,
  },
  mixins: [heightTransition],
  props: ['imgID', 'image', 'desc'],
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      id: 0,
      img: '',
      data: {
        description: '',
      },
      required,
    }
  },
  mounted() {
    this.img = this.image
    this.id = this.imgID
    this.data.description = this.desc
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.img.includes('data:image/')) {
            this.data.file = this.img.split(',').map((item) => item.trim())
            this.data.file = this.data.file[1]
          }
          axios
            .put('items-guide/images/' + this.id + '/update', this.data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(0)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        this.img = e.target.result
      }
      reader.readAsDataURL(file)
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
<style lang=""></style>
